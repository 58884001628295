var infoCookie = 'info';

function acceptCookies(){
    $('#cookiesModal').hide();
    $.cookie(infoCookie, 1, {"expires": 365});
};

$(document).ready(function(){
    var isInfo = $.cookie(infoCookie);
    if(!isInfo){
        $('#cookiesModal').removeClass('hidden');
    }else{
        $('#cookiesModal').hide();
        acceptCookies();
    }
});
