$(document).ready(function(){
//    
//    $('.venue-item-gallery').slick({
//        prevArrow: '<span class="left-arrow"><i class="fa fa-angle-left"></i></span>',
//        nextArrow: '<span class="right-arrow"><i class="fa fa-angle-right"></i></span>',
//        slickFilter: 'li'
//    });
//   console.log($('.venue-item-gallery').slick);

    $('.venue-item-gallery-holder').each(function(){
        var slickInduvidual = $(this);
        var el = $(this);
        /*
        $(this).find('.venue-item-prices-holder').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: el.find('.venue-item-gallery'),
            slide: 'li',
        });
        */

        //asNavFor: el.find('.venue-item-prices-holder'),
        
        $(this).find('.venue-item-gallery').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<span class="left-arrow"><i class="fa fa-angle-left"></i></span>',
            nextArrow: '<span class="right-arrow"><i class="fa fa-angle-right"></i></span>',
            slide: 'li',
            dots: false
        });
    });
});