$(document).ready(function(){
    

//    $('.input-group.date').datetimepicker();
    //$('#datetimepicker1').datetimepicker();
    
    $('#start-date').datetimepicker({
        format: 'YYYY-MM-DD',
        showTodayButton: true
    });


    $('#event-date').datetimepicker({
        format: 'YYYY-MM-DD',
        showTodayButton: false,

    });

    $('.calendar_for_all').datetimepicker({
        format: 'YYYY-MM-DD',
        showTodayButton: false,

    });

     // With JQuery
    $("#ex2").slider({});


    $('[data-toggle="tooltip"]').tooltip()

    $('[data-toggle=confirmation]').confirmation({
      rootSelector: '[data-toggle=confirmation]',
    });

    var flyingButton = $('#flying-button');

    if(flyingButton.length){

        var btnSavedPosition = flyingButton.offset().top;
            if(btnSavedPosition){
                $('#flying-button').addClass('fixed');
            }


        $(window).scroll(function() {

               hH = $('#scroll-to').outerHeight(),
               wH = $(window).height(),
               wS = $(this).scrollTop();

            if (wS > (btnSavedPosition+hH-wH)){
                $('#flying-button').removeClass('fixed');
            }else{
                $('#flying-button').addClass('fixed');
            }
           
        });

    }

    lightbox.option({
        'resizeDuration': 400,
        'wrapAround': false
    })

    //$("#ex2")
    // Without JQuery
    // var slider = new Slider('#ex2', {});   

    // $(function()
    // {
    //     window.onorientationchange = OrientationChanged;
    //     window.setTimeout(OrientationChanged, 0);
    // },
    // function OrientationChanged(e)
    // {
    //     $('body').css('-webkit-transform', window.orientation % 180 == 0 ? '' : 'rotate(-90deg)');
    // })

});

