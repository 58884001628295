$(function(){
  var handleClick = 0,
      percentage = 0;
  $('.slider').mousemove(function(e){
    curSlider = $(this);
    curPos = e.pageX - curSlider.offset().left;
    
    if(handleClick === 1){
     if(curPos + curSlider.find('.handle').width() / 2 <= curSlider.width()){
       curSlider.find('.progress').css('width',curPos - curSlider.find('.handle').width() / 2 + 'px'); 
       curSlider.find('.progressLeft').css('width', curSlider.width() - curSlider.find('.handle').width() - curSlider.find('.progress').width() + 'px');
     }
     percentage = Math.round(curSlider.find('.progress').width() / (curSlider.width() - curSlider.find('.handle').width()) * 100);
     curSlider.next('input[type=text]').val(percentage + '%');
    }
  });
  
  $('.slider').mousedown(function(){
    handleClick = 1;
  });
  
  $('body').mouseup(function(){
    handleClick = 0;
  });
})