/*
jQuery(window).bind('orientationchange', function(e) {
    console.log('Window rotated');
    console.log(window.orientation);

    //if($(window).innerHeight(200)){
        switch ( window.orientation ) {
        case 0:
        $('.turnDeviceNotification').css('display', 'none');
        console.log(window.orientation);
        console.log(0);
        // The device is in portrait mode now
        break;
    
        case 180:
        $('.turnDeviceNotification').css('display', 'none');
        console.log(window.orientation);
        console.log(180);
        // The device is in portrait mode now
        break;
    
        case 90:
        // The device is in landscape now
        $('.turnDeviceNotification').css('display', 'block');
        console.log(window.orientation);
        console.log(90);
        break;
    
        case -90:
        // The device is in landscape now
        $('.turnDeviceNotification').css('display', 'block');
        console.log(window.orientation);
        console.log(-90);
        break;
        }
    //}
});
*/