var NAV = {
    el: null,
    topOffset: 0,
    height: 0,
    selector: "#top-menu-holder",
    prevent: false,
    minWindowWIdth: 620, // min window width to fire fixed header 

    init: function () {
        this.el = $(this.selector);
        if (this.el.length) {
            if(this.el.hasClass('fixed-allways')) return;
            this.topOffset = this.el.offset().top;
            this.height = this.el.height();
            this.setFixed(this);
            this.addSpacer();
            this.bind();
        }
    },

    addSpacer: function(){
        this.el.parent().prepend('<div id="nav-spacer" style="display:none; position: relative; height: '+ this.height +'"></div>');
    },

    bind: function(){
        this.windowScroll();
        this.windowResize();
    },

    windowScroll: function(){
        var self = this;
        window.addEventListener('scroll', function(){
            if(self.prevent) return;
            if ($(window).scrollTop() >= self.topOffset){
                self.el.addClass('fixed');
                //this makes the page length equal to what it was before fixing nav
                $('#nav-spacer').show(0); 
            }
            else {
                self.el.removeClass('fixed');
                $('#nav-spacer').hide(0);
            }
        });
        // $(window).on('scroll', function(){
        //     if(self.prevent) return;
        //     if ($(window).scrollTop() >= self.topOffset){
        //         self.el.addClass('fixed');
        //         //this makes the page length equal to what it was before fixing nav
        //         $('#nav-spacer').show(0); 
        //     }
        //     else {
        //         self.el.removeClass('fixed');
        //         $('#nav-spacer').hide(0);
        //     }
        // });
    },

    setFixed: function(){
        var self = this;
        if ($(window).scrollTop() >= self.topOffset){
            self.el.addClass('fixed');
            //this makes the page length equal to what it was before fixing nav
            $('#nav-spacer').show(0); 
        }
        else {
            self.el.removeClass('fixed');
            $('#nav-spacer').hide(0);
        }
    },

    windowResize: function(){
        var self = this;
        if($(window).innerWidth() < self.minWindowWIdth){
            self.setResponsiveFixed();
        };
    
        $(window).on('resize', function(){
            if($(window).innerWidth() < self.minWindowWIdth){
                self.setResponsiveFixed();
            }else{
                self.removeResponsiveFixed();
            }
        });
    },
    
    setResponsiveFixed: function(){
        this.prevent = true;
        this.el.addClass('fixed');
    }, 

        
    removeResponsiveFixed: function(){
        if(!this.prevent) return;
        this.prevent = false;
        this.el.removeClass('fixed');
    }
}



$(document).ready(function(){

    NAV.init();
});
