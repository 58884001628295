var iDevice =  (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) ? true : false;
var $currentScrollPos = 0;


function setBodyPosition(fixed){
    if(!iDevice) return;
    if(fixed){
        $('body').css({
            'position': 'fixed'
        });
        localStorage.cachedScrollPos = $currentScrollPos;
    }else{
        $('body').css({
            'position': 'relative'
        });
        $('body').scrollTop(localStorage.cachedScrollPos);
    }
}

$(document).ready(function(){
    

    if (iDevice) {
        $(window).scroll(function () {
            $currentScrollPos = $(document).scrollTop();
        });
    }

    $('#top-menu .m-depth-0').slicknav({
        prependTo:'body',
        duplicate: true,
        
        beforeOpen: function(trigger){
            this.brand= ''
        },
        beforeClose: function(trigger){
            this.brand= '';
            //$('.slicknav_menu').animate({height: 0}, 300, function(){ $('.slicknav_menu').toggleClass('active') });

        }
    });

    $('header').on('click', '#show-menu', function(e){
        e.preventDefault();
        $('#top-menu .m-depth-0').slicknav('toggle');
        $('body').addClass('hidden-overflow');
        $('.slicknav_menu').animate({
            height: '100%'}, 
            300, 
            function(){ 
                $('.slicknav_menu').toggleClass('active');
                setBodyPosition(true);
            });
        
    });

    $('body').on('click', 'a.slicknav_btn', function(){
        
        //$('#top-menu .m-depth-0').slicknav('toggle');
        $('.slicknav_menu').animate({height: 0}, 
            300, 
            function(){ 
                $('body').removeClass('hidden-overflow');
                $('.slicknav_menu').toggleClass('active');
                setBodyPosition(false);
        });

    });


});